import type { GetServerSideProps } from "next";
import Meta from "../app/component/commons/meta/Meta";
import DefaultLayout from "../app/layouts/DefaultLayout";
import { useEffect, useState } from "react";
import { client } from "../app/utils/axiosStrapi";
import { ReviewsProps } from "../app/helpers/types/getTypes";
import Dashboard from "../app/component/pages/Dashboard";

type HomeProps = {
  params: {
    reviews: ReviewsProps[];
  };
};

const Home = ({ params }: HomeProps) => {
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("desktop");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta
        title="Find Storage Unit Auctions Near You | Storage Unit Auction List"
        description="Find more storage unit auctions happening near you with Storage Unit Auction Lists national auction database for storage unit buyers. Sign up today to get a list of the upcoming auctions in your area."
      />
      <DefaultLayout>
        {mode && <Dashboard mode={mode} reviews={params.reviews} />}
      </DefaultLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  let params = {};

  try {
    const { data } = await client.get(`/reviews`, {
      ...context,
      params: {
        populate: "*",
      },
    });

    if (data) {
      params = { reviews: data.data };
    }

    return {
      props: { params },
    };
  } catch (error: any) {
    if (error.response) {
      return {
        redirect: {
          permanent: false,
          destination: `/${error.response.status}`,
        },
      };
    }

    return {
      redirect: {
        permanent: false,
        destination: "/404",
      },
    };
  }
};

export default Home;
